


import Component from 'vue-class-component';
import { Prop, Vue, Watch } from 'vue-property-decorator';
import { Moment } from 'moment';
import { TConferenceProgram } from '@/_modules/promo/types/conference-program.type';
import { DateTimeFormat } from '@/_types/date-time-format.enum';
import HorizontalMenu from '@/_modules/controls/components/horizontal-menu/horizontal-menu.vue';
import HorizontalMenuItem from '@/_modules/controls/components/horizontal-menu-item/horizontal-menu-item.vue';
import HorizontalMenuArrowLeft
  from '@/_modules/controls/components/horizontal-menu-arrow-left/horizontal-menu-arrow-left.vue';
import HorizontalMenuArrowRight
  from '@/_modules/controls/components/horizontal-menu-arrow-right/horizontal-menu-arrow-right.vue';
import { TContact } from '@/_types/contact.type';
import Avatar from '@/_components/avatar/avatar.vue';
import IconLiveIndicator from '@/_modules/icons/components/icon-live-indicator.vue';
import IconStarToggler from '@/_modules/icons/components/icon-star-toggler.vue';
import eventDiscoveryService from '@/_services/event-discovery.service';
import {DiscoveryMessageType} from '@/_types/discovery/discovery-message-type.enum';

@Component({
  components: {
    HorizontalMenu,
    HorizontalMenuItem,
    HorizontalMenuArrowLeft,
    HorizontalMenuArrowRight,
    Avatar,
    IconLiveIndicator,
    IconStarToggler,
  }
})
export default class PromoProgramListItem extends Vue {

  @Prop({ type: Object })
  public readonly program: TConferenceProgram;

  @Prop({ type: Boolean, default: false })
  public readonly hideFavorite: boolean;

  public isLive: boolean = false;
  public visibleSpeakers: TContact[] = [];
  public firstVisibleSpeakerIndex: number = 0;
  public isFavorite: boolean = false;

  private updateNowInterval: number = null;

  public created(): void {
    this.isFavorite = !!(this.program && this.program.is_favorite);
    this.updateIsLive();
    this.updateNowInterval = window.setInterval(() => {
      this.updateIsLive();
    }, 10000);
  }

  public beforeDestroy(): void {
    if (this.updateNowInterval) {
      window.clearInterval(this.updateNowInterval);
      this.updateNowInterval = null;
    }
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  public get programIdParam(): number {
    return this.$route.params.programId ? parseInt(this.$route.params.programId, 10) : null;
  }

  public get dateStartMoment(): Moment {

    return (this.program && this.program.date_start) ? this.$moment(this.program.date_start) : null;
  }

  public get dateEndMoment(): Moment {
    return (this.program && this.program.date_end) ? this.$moment(this.program.date_end) : null;
  }

  public get timeRange(): string {
    const dateStartMoment = this.dateStartMoment;
    const dateEndMoment = this.dateEndMoment;
    let result = '';
    if (dateStartMoment) {
      result += dateStartMoment.format(DateTimeFormat.HOURS_MINUTES);
    }
    if (dateEndMoment) {
      if (result) {
        result += ' - ';
      }
      result += dateEndMoment.format(DateTimeFormat.HOURS_MINUTES);
    }
    return result;
  }

  public get uniqueProgramSpeakers(): TContact[] {

    // This was made for AW-2021
    if (!this.program || !this.program.speakers || !this.program.speakers.length) {
      return [];
    }
    const speakers = this.program.speakers;
    const speakersById: { [key: number]: TContact } = {};
    speakers.forEach((speaker) => {
      speakersById[speaker.id] = speaker;
    });
    return Object.values(speakersById);
  }

  public get isSpeakersMenuArrowsVisible(): boolean {
    if (!this.program || !this.uniqueProgramSpeakers.length) {
      return false;
    }
    return this.uniqueProgramSpeakers.length > 3;
  }

  public get isSpeakersMenuArrowLeftDisabled(): boolean {
    if (!this.program || !this.uniqueProgramSpeakers.length) {
      return true;
    }
    return this.firstVisibleSpeakerIndex < 1;
  }

  public get isSpeakersMenuArrowRightDisabled(): boolean {
    if (!this.program || !this.uniqueProgramSpeakers.length) {
      return true;
    }
    return this.firstVisibleSpeakerIndex >= this.uniqueProgramSpeakers.length - 3;
  }

  public toggleFavorite(event: MouseEvent): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();

    this.isFavorite = !this.isFavorite;

    this.$store.dispatch('promoProgramStore/toggleFavorite', {
      eventId: this.eventId,
      conferenceRoomId: this.program.conference_id,
      programId: this.program.id,
      isFavorite: this.isFavorite,
    });
  }

  public onSpeakersMenuArrowLeftClick(): void {
    if (!this.program || !this.uniqueProgramSpeakers.length || this.firstVisibleSpeakerIndex < 1) {
      return;
    }
    this.firstVisibleSpeakerIndex--;
    this.setVisibleSpeakers();
  }

  public onSpeakersMenuArrowRightClick(): void {
    if (
      !this.program
      || !this.uniqueProgramSpeakers.length
      || this.firstVisibleSpeakerIndex >= this.uniqueProgramSpeakers.length - 3
    ) {
      return;
    }
    this.firstVisibleSpeakerIndex++;
    this.setVisibleSpeakers();
  }

  @Watch('program', { immediate: true })
  private onProgramChange(): void {
    this.firstVisibleSpeakerIndex = 0;
    this.setVisibleSpeakers();

    this.isFavorite = !!(this.program && this.program.is_favorite);
  }

  private updateIsLive(): void {
    const dateStartMoment = this.dateStartMoment;
    const dateEndMoment = this.dateEndMoment;
    if (!dateStartMoment || !dateEndMoment) {
      this.isLive = false;
    } else {
      this.isLive = this.$moment().isBetween(dateStartMoment, dateEndMoment);
    }
  }

  private setVisibleSpeakers(): void {
    this.visibleSpeakers = [];

    if (!this.program || !this.uniqueProgramSpeakers.length) {
      return;
    }

    const speakers = this.uniqueProgramSpeakers;
    if (speakers.length <= 3) {
      this.visibleSpeakers = [ ...speakers ];
      return;
    }

    if (this.firstVisibleSpeakerIndex >= speakers.length - 3) {
      this.visibleSpeakers = speakers.slice(speakers.length - 3);
    } else if (this.firstVisibleSpeakerIndex < 1) {
      this.visibleSpeakers = speakers.slice(0, 3);
    } else {
      this.visibleSpeakers = speakers.slice(
        this.firstVisibleSpeakerIndex,
        this.firstVisibleSpeakerIndex + 3,
      );
    }
  }

  public onPresenceButtonClick(id: number): void {
    const targetButton = document.querySelector(`.button-${id}`);
    targetButton.classList.add('highlight');

    eventDiscoveryService.sendPresenceQuestion({
      type: DiscoveryMessageType.SESSION_ONLINE_CHECK,
      programSessionId: id,
      isShowPresenceQuestion: true,
    });

  }
}
